import React, { Component } from 'react';
import './App.css';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import { Jumbotron, Col, Input, InputGroup, InputGroupAddon, FormGroup, Label, Button, Fade, FormFeedback, Container, Card } from 'reactstrap';

class App extends Component {
  constructor(props){
    super(props);
    this.state={
      isOpen: false,
      isOpen2: false,
      dataLoaded: false,
      dataLoaded2: false,
      isFormInvalid: false,
      rows: null,
      rows2: null,
      cols: null,
      col2:null
    }
    this.fileHandler = this.fileHandler.bind(this);
    this.fileHandler2 = this.fileHandler2.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.openFileBrowser = this.openFileBrowser.bind(this);
    this.openFileBrowser2 = this.openFileBrowser2.bind(this);
    this.renderFile = this.renderFile.bind(this);
    this.renderFile2 = this.renderFile2.bind(this);
    this.fileInput = React.createRef();
    this.fileInput2 = React.createRef();
  }

  renderFile = (fileObj) => {
      //just pass the fileObj as parameter
      ExcelRenderer(fileObj, (err, resp) => {
        if(err){
          console.log(err);            
        }
        else{
          this.setState({
            dataLoaded: true,
            cols: resp.cols,
            rows: resp.rows,
            cols2: [],
            rows2: [],
          });
        }
      }); 
  }
  renderFile2 = (fileObj) => {
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if(err){
        console.log(err);            
      }
      else{
        this.setState({
          dataLoaded2: true,
          cols2: resp.cols,
          rows2: resp.rows,
          cols: [],
          rows: []
        });
      }
    }); 
}

  fileHandler = (event) => {    
    if(event.target.files.length){
      let fileObj = event.target.files[0];
      let fileName = fileObj.name;

      
      //check for file extension and pass only if it is .xlsx and display error message otherwise
      if(fileName.slice(fileName.lastIndexOf('.')+1) === "xlsx"){
        this.setState({
          uploadedFileName: fileName,
          isFormInvalid: false
        });
        this.renderFile(fileObj)
      }    
      else{
        this.setState({
          isFormInvalid: true,
          uploadedFileName: ""
        })
      }
    }               
  }
  fileHandler2 = (event) => {    
    if(event.target.files.length){
      let fileObj = event.target.files[0];
      let fileName = fileObj.name;

      
      //check for file extension and pass only if it is .xlsx and display error message otherwise
      if(fileName.slice(fileName.lastIndexOf('.')+1) === "xlsx"){
        this.setState({
          uploadedFileName2: fileName,
          isFormInvalid2: false
        });
        this.renderFile2(fileObj)
      }    
      else{
        this.setState({
          isFormInvalid2: true,
          uploadedFileName2: ""
        })
      }
    }               
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  toggle2() {
    this.setState({
      isOpen2: !this.state.isOpen2
    });
  }
  openFileBrowser = () => {
    this.fileInput.current.click();
  }
  openFileBrowser2 = () => {
    this.fileInput2.current.click();
  }
  render() {
    return (
      <div>
        <div>
          <Jumbotron className="jumbotron-background">          
              <h1 className="display-3">Viga-Tools</h1>
              <p className="lead">Welcome to Viga-Tools</p>  
           
              <hr className="my-2" />
              <p>Developed with <span className="fa fa-heart"></span> by Vigalab.</p>
          </Jumbotron>
        </div>
        <Container>
        <form>
          <FormGroup row>
            <Label for="exampleFile" xs={6} sm={4} lg={2} size="lg">Ingresos Codelco</Label>          
            <Col xs={4} sm={8} lg={10}>                                                     
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <Button color="info" style={{color: "white", zIndex: 0}} onClick={this.openFileBrowser.bind(this)}><i className="cui-file"></i> Browse&hellip;</Button>
                  <input type="file" hidden onChange={this.fileHandler.bind(this)} ref={this.fileInput} onClick={(event)=> { event.target.value = null }} style={{"padding":"10px"}} />                                
                </InputGroupAddon>
                <Input type="text" className="form-control" value={this.state.uploadedFileName} readOnly invalid={this.state.isFormInvalid} />                                              
                <FormFeedback>    
                  <Fade in={this.state.isFormInvalid} tag="h6" style={{fontStyle: "italic"}}>
                    Please select a .xlsx file only !
                  </Fade>                                                                
                </FormFeedback>
              </InputGroup>     
            </Col>                                                   
          </FormGroup>   
        </form>
        <form>
          <FormGroup row>
            <Label for="exampleFile" xs={6} sm={4} lg={2} size="lg">Usuarios Local</Label>          
            <Col xs={4} sm={8} lg={10}>                                                     
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <Button color="info" style={{color: "white", zIndex: 0}} onClick={this.openFileBrowser2.bind(this)}><i className="cui-file"></i> Browse&hellip;</Button>
                  <input type="file" hidden onChange={this.fileHandler2.bind(this)} ref={this.fileInput2} onClick={(event)=> { event.target.value = null }} style={{"padding":"10px"}} />                                
                </InputGroupAddon>
                <Input type="text" className="form-control" value={this.state.uploadedFileName2} readOnly invalid={this.state.isFormInvalid2} />                                              
                <FormFeedback>    
                  <Fade in={this.state.isFormInvalid2} tag="h6" style={{fontStyle: "italic"}}>
                    Please select a .xlsx file only !
                  </Fade>                                                                
                </FormFeedback>
              </InputGroup>     
            </Col>                                                   
          </FormGroup>   
        </form>
        {this.state.dataLoaded && 
        <div>
       
          {this.state.rows.map(row=>(
           
            <text>
  {/* {row[0]>0?"UPDATE codelco.pulsera SET numero_impersonal='" + row[1] + "' WHERE id= "+row[0]+ ";": ""} */}
{ row[0]>0?"call codelco.vigatoolscambiopulsera(" + row[0] +",'" + row[1] + "','" + row[2] + "'," + row[3] + ");"
:"" }
          <br />
          </text>
          ))}
         
        </div>}
        {this.state.dataLoaded2 && 
        <div>
       
          {this.state.rows2.map(row=>(
           
            <text>
{ row[0]>0?"call codelco.vigatoolsIngresoUsuarioLocal(" + row[0] +",'" + row[1] +"','" + row[2] + "','" + row[3] + "','" + row[4] + "','" + row[5] + "','" + row[6] + "','" + row[7] + "','" + row[8] + "','" + row[9] + "','" +( row[10]== undefined?"-":row[10]) + "');"
:"" }
          <br />
          </text>
          ))}
         
        </div>}
     
        </Container>
      </div>
    );
  }
}

export default App;
